<template>
  <b-modal
    id="AddContent"
    centered
    size="lg"
    no-close-on-backdrop
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.save')"
    cancel-title="Отменит"
    :title="$t('courses.add_content')"
    hide-footer
    @show="openModal"
    @ok.prevent="saveContent"
  >
    <b-row>
      <b-col
        md="6"
        class="left"
      >
        <tree-collapse
          class="min-w"
          :all-options="false"
          :folder="GET_FOLDER_LIST"
          @show-file="onShowFile"
          @to-right="toRight"
        />
      </b-col>
      <b-col
        md="6"
      >
        <ul
          class="w-100 tree-node p-0"
        >
          <li
            v-for="item in list"
            :key="item.id"
            class="w-100 py-10 position-relative tree-content"
          >
            <div
              :title="item.file ? '' : item.url"
              class="file-text pr-3"
            >
              <feather-icon
                :class="item.file ? '' : 'text-primary'"
                :icon="item.file ? 'FileIcon' : 'LinkIcon'"
                size="20"
                class="mr-50"
              />
              <span>{{ item.file ? showFile(item.file) : 'url' }}</span>
            </div>

            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle position-absolute bl"
              @click.stop="deleteContent(item)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="20"
              />
            </b-button>
          </li>
        </ul>
      </b-col>
    </b-row>
    <show-file-modal
      :src="src"
      @hide="onHideFile"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import TreeCollapse from '@/components/TreeCollapse.vue'
import showFileModal from './showFileModal.vue'

export default {
  components: {
    TreeCollapse,
    BModal,
    BRow,
    BCol,
    BButton,
    showFileModal,
  },
  directives: {
    Ripple,
  },
  props: {
    defSelectet: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
      src: null,
    }
  },
  computed: {
    ...mapGetters('content', ['GET_FOLDER_LIST', 'GET_ONE_FOLDER']),
  },
  mounted() {
    this.FETCH_FOLDERS_LIST()
  },
  methods: {
    ...mapActions('content', [
      'FETCH_FOLDERS_LIST',
      'FETCH_ONE_FOLDER',
      'DELETE_FOLDERS',
    ]),
    openModal() {
      this.list = this.defSelectet
    },
    saveContent() {
      // const data = this.list.map(el => el.id)
      this.$emit('saveContent', this.list)
      this.$nextTick(() => {
        this.$bvModal.hide('AddContent')
      })
    },
    toRight(e, id) {
      const idList = this.list.map(el => el.id)
      if (!idList.includes(id)) {
        this.list.push(e)
      }
    },
    onShowFile(value) {
      if (value.file) {
        this.src = value.file
      } else {
        this.$nextTick(() => {
          this.src = value.url
        })
      }
      this.$bvModal.show('ShowContent')
    },
    onHideFile() {
      this.src = null
    },
    deleteContent(item) {
      const index = this.list.indexOf(item)
      this.list.splice(index, 1)
    },
    showFile(item) {
      const title = item.split('/')
      return title[title.length - 1]
    },
  },
}
</script>

<style>
.left {
  height: 300px;
  overflow: auto;
}
.min-w {
  min-width: 300px;
}
.tree-node:not(.selected) > .tree-content:hover {
  background: #f6f8fb;
}
.py-10 {
  padding: 8px 0 !important;
}
.tree-content {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
</style>

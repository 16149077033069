<template>
  <div>

    <b-card>
      <b-button-group class="mb-2">
        <b-button
          class="border-radius-0"
          :variant="card? 'outline-secondary' : 'primary'"
          @click="check(false)"
        >
          {{ $t('courses.content') }}
        </b-button>
        <b-button
          class="border-radius-0"
          :variant="card? 'primary' : 'outline-secondary'"
          @click="check(true)"
        >
          {{ $t('courses.test') }}
        </b-button>
      </b-button-group>
      <quill-editor
        v-model="content.description"
      />
    </b-card>

    <b-card>
      <b-row>

        <b-col
          class="text-right mb-1"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addCourse"
          >
            <feather-icon
              icon="PlusIcon"
            />
            {{ $t('general.add') }}
          </b-button>
        </b-col>
        <b-col cols="12">

          <b-table
            hover
            responsive
            show-empty
            :fields="fields"
            :items="content.folder_files"
            style-class="slots_text_align vgt-table bordered"
          >
            <template #head()="{ label }">
              {{ $t(label) }}
            </template>
            <template
              #empty
            >
              <div class="text-center text-secondary">
                {{ $t('general.no_data_to_view') }}
              </div>
            </template>
            <template #cell(title)="data">
              {{ data.item.file ? showFile(data.item.file) : data.item.url }}
            </template>
            <template #cell(type)="data">
              <b-badge :variant="data.item.file ? 'success' : 'primary'">
                {{ data.item.file ? 'File' : 'Url' }}
              </b-badge>
            </template>
            <template
              #cell(actions)="data"
            >
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon p-25"
                  size="sm"
                  @click="deleteContent(data)"
                >
                  <feather-icon
                    size="22"
                    icon="Trash2Icon"
                  />
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>
      </b-row>

    </b-card>
    <b-col
      cols="12"
      class="text-right mt-3"
    >

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="saveContent"
      >
        {{ $t('general.save') }}
      </b-button>
    </b-col>
    <AddContentModal
      :def-selectet="defSelectet"
      @saveContent="save"
    />
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BBadge,
  BButtonGroup,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AddContentModal from './components/AddContentModal.vue'

export default {
  components: {
    quillEditor,
    BTable,
    BCard,
    BCol,
    BRow,
    BButton,
    BBadge,
    BButtonGroup,
    AddContentModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      content: {
        folder_files: [],
        description: '',
      },
      defSelectet: [],
      card: false,
      fields: [
        {
          label: 'general.title',
          key: 'title',
        },
        {
          label: 'courses.type',
          key: 'type',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_LESSON_CONTENT', 'GET_LESSON']),
  },
  mounted() {
    this.FETCH_LESSON(this.$route.params.themeId)
      .then(() => {
        if (this.GET_LESSON.contents.length) {
          // eslint-disable-next-line prefer-destructuring
          this.content = this.GET_LESSON.contents[0]
        } else {
          this.content = {
            folder_files: [],
            description: '',
          }
        }

        this.updateBreadcrumb()
      })
  },
  methods: {
    ...mapActions('courses', ['FETCH_LESSON_CONTENT', 'CREATE_LESSON_CONTENT', 'UPDATE_LESSON_CONTENT', 'FETCH_LESSON']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    addCourse() {
      this.defSelectet = this.content.folder_files
      this.$nextTick(() => {
        this.$bvModal.show('AddContent')
      })
    },
    check(type) {
      this.card = type
    },
    save(list) {
      this.content.folder_files = list
    },
    refresh() {
      this.FETCH_LESSON(this.$route.params.themeId)
        .then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.content = this.GET_LESSON.contents[0]
        })
    },
    saveContent() {
      const data = this.$_removeObjectConnection(this.content)
      data.folder_files = data.folder_files.map(el => el.id)
      this.$set(data, 'lesson', this.$route.params.themeId)
      if (!this.content.id) {
        this.CREATE_LESSON_CONTENT(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.refresh()
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      } else {
        this.UPDATE_LESSON_CONTENT(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.refresh()
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      }
    },
    deleteContent(item) {
      const { index } = item
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.content.folder_files.splice(index, 1)
        }
      })
    },
    showFile(item) {
      const title = item.split('/')
      return title[title.length - 1]
    },
    updateBreadcrumb() {
      const [first, second, three] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.to = `/course/course-administrations/theme-list/${this.$route.params.id}`
      second.active = false
      three.text = this.GET_LESSON.title
      this.UPDATE_BREADCRUMB([first, second, three])
    },
  },
}
</script>
<style>
.ql-editor{
  height: 200px!important;
}
</style>
